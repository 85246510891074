.checkout-page-content {

    .form-check {
        height: 26px;
        display: flex;
        align-items: center;
        margin-bottom: 0;

        .form-check-input {
            position: unset;
            margin-left: 0;
            margin-top: 0;
        }

        .form-check-label {
            font-weight: 600;
            padding-left: 0;
        }
    }

    .tt-title-subpages:not(.text-start):not(.text-right) {
        text-align: center;
    }


    .accordion-container {
        .accordion-item {
            display: flex;
            flex-flow: column nowrap;
            border-top: 1px solid #e7e7e7;
            margin-bottom: 30px;

            .header {
                padding: 10px 10px 10px 0px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid #e7e7e7;
                cursor: pointer;

                @media #{$sm-device} {
                    .comments {
                        display: none;
                    }
                }

                .comments {
                    font-size: 20px;
                    cursor: pointer;
                }

                button {
                    border: none;
                    outline: none;
                    background: none;
                    padding: 10px 10px;
                }

                h4.title {
                    padding-bottom: 0px;
                    min-width: 180px;

                    &.active {
                        color: #5142fc;
                    }
                }
            }

            .body {
                padding: 10px 10px;

                .card {
                    .card-header {
                        background-color: #fefefe;

                    }
                }

            }
        }


    }

    .p-button {
        padding: 3px 15px;
    }

    .shipping-address-wrap {
        .add-new-address {
            margin-bottom: 4px;
        }
    }

    .add-new-address-modal {
        .modal-dialog {
            .modal-content {
                width: 800px;

                .modal-header {
                    justify-content: center;
                    align-items: center;

                    .modal-title {
                        margin-top: 0px;
                        padding-bottom: 0px;
                    }
                }

                .modal-body {
                    .form-group {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        label {
                            min-width: 160px
                        }
                    }
                }
            }
        }
    }

    .payment-method-wrap {
        .billing {
            padding-bottom: 10px;
            padding-top: 20px;
        }

        .comments {
            margin: 12px 0;
        }


        .card {
            margin-bottom: 2px;
            background-clip: unset;
            border-radius: unset;
        }

        .credit.card-body {
            .form-group {
                margin-top: 8px;
            }

            .date {
                display: flex;

                .form-group {
                    width: 250px;
                    margin-right: 10px;
                }
            }
        }

        .billing-address.card-body {

            .form-group {
                margin-top: 8px;
            }
        }

        .form-group {
            margin-left: 10px;
            margin-right: 10px;


            input {
                height: 38px;
                padding: 3px 15px;
            }

            select {
                height: 38px;

                padding: 3px 15px;
                color: #8A8AA0;
                font-size: 18px;

                &:focus {
                    border: 1px solid #8A8AA0;
                }
            }
        }
    }

    .review-items-and-shipping-wrap {

        .shipping-header {
            padding: 10px 0;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            h6.comments {
                margin-right: 20px;
                padding-bottom: 0px !important;
            }
        }

        .shipping-item-wrap {
            display: flex;
            flex-flow: row wrap;

            .shipping-item {
                border: 1px solid #e7e7e7;
                padding: 8px 8px;
                width: 460px;
                margin: 2px 2px;

                .desc {
                    display: flex;
                    align-items: center;

                    .badge {
                        background-color: green;
                        color: white;
                        margin-right: 8px;
                    }
                }

                h6 {
                    padding-bottom: 4px;
                }

                .price {
                    display: flex;
                    justify-content: space-between;
                    background-color: rgb(168, 243, 160);
                    align-items: center;
                    padding: 10px 4px;
                    font-weight: 600;

                    .l {
                        font-size: 18px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        .old {
                            text-decoration: line-through;
                            color: red;
                        }
                    }

                    .r {
                        color: #191919;
                        font-size: 24px;
                    }
                }

                .footer {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 10px 4px;

                    .btn {
                        padding: 2px 12px;
                    }

                }
            }
        }
    }
}

.add-new-address-modal {
    .p-button {
        padding: 3px 15px;
    }

    .form-group {
        margin-left: 10px;
        margin-right: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:focus {
            border: 1px solid #8A8AA0;
        }

        label {
            font-weight: 600;
            font-size: 16px;
            min-width: 142px;
            text-align: center;
        }

        input {
            height: 38px;
            padding: 3px 15px;
        }

        select {
            height: 38px !important;

            padding: 3px 15px;
            color: #8A8AA0;
            font-size: 18px;

            &:focus {
                border: 1px solid #8A8AA0;
            }
        }
    }
}

.order-details-area-wrap {
    border: 1px solid #e9e7e7;
    padding: 10px 10px;

    table {
        font-size: 16px;
        border-collapse: collapse;
        border: 0;

        tr,
        thead,
        th,
        td {
            border: 0;
        }

        thead {
            border-bottom: 1px solid #e9e7e7;
        }

        tr th:last-child,
        tr td:last-child {
            width: 30%;
            text-align: right;
        }

        tbody tr:last-child {
            border-bottom: 1px solid #e9e7e7;
        }
    }

    .p-button {
        padding: 12px 34px;
        margin-top: 10px;
        width: 100%;
    }

    .order-details-footer {
        p {
            font-size: 12px;
        }
    }
}



.is_dark {
    .checkout-page-content .card-header {
        .form-check-label {
            color: var(--primary-color);
        }


    }

    .add-new-address-modal .form-group select {
        background-color: var(--primary-color);
        border: 1px solid rgba(138, 138, 160, 0.3);
    }
}