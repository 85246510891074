.is_dark .product-detail-wrapper .tt-product-vertical-layout .tt-product-single-carousel-vertical .slick-slider:before {
    background: unset;
    background-color: black;
}

.product-detail-wrapper {


    .tt-product-vertical-layout {
        position: relative;
        overflow: hidden;

        .tt-product-single-img {
            float: right;
            width: 82.7%;
            position: relative;

            img {
                width: 100%;
                height: auto;
            }
        }

        .tt-product-single-carousel-vertical {
            margin: 0 0 44px 0;
            top: 0;
            left: 0;
            width: 13.9%;
            position: absolute;

            .slick-slider {
                width: 100%;
                padding: 0;
                margin: 0;

                .slick-slide {
                    padding-bottom: 20px;

                    &.slick-current {
                        >div {
                            position: relative;

                            &:before {
                                background-color: $default_color;
                                content: "";
                                opacity: 0.1;
                                position: absolute;
                                left: 0;
                                top: 0;
                                height: 100%;
                                width: 100%;
                            }
                        }
                    }
                }

                .slick-slide a {
                    display: block;
                    position: relative;
                }

                .slick-slide a:before {
                    content: '';
                    display: block;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background: $product_slider_vertical_active_img;
                    opacity: 0;
                    transition: opacity $value_speed linear;
                }

                .slick-slide:hover a:before,
                .slick-slide .zoomGalleryActive:before {
                    opacity: 0.6;
                }
            }

            .slick-slider:before {
                content: '';
                display: block;
                width: 100%;
                height: 14px;
                position: absolute;
                bottom: 0;
                z-index: 1;
                background: -webkit-linear-gradient(bottom, $default_bg_color, white 20%);
                background: -o-linear-gradient(bottom, $default_bg_color, white 20%);
                background: -moz-linear-gradient(bottom, $default_bg_color, white 20%);
                background: linear-gradient(to bottom, $default_bg_color, white 20%);
            }


        }


        /* slick (product.html) */
        .tt-slick-button-vertical {
            font-size: 0;
            line-height: 0;

            .slick-arrow {
                z-index: 7;
                cursor: pointer;
                font-size: 0;
                line-height: 0;
                background: none;
                border: none;
                width: 38px;
                height: 38px;
                background: $slick_button_vertical_bg;
                color: $slick_button_vertical_text;
                font-weight: 500;
                border-radius: 50%;
                transition: all $speed linear;
                padding: 0 0;
                top: auto;
                bottom: -33px;
                position: absolute;
                margin-top: 0;
                z-index: 2;
            }

            .slick-arrow:hover {
                background: $slick_button_vertical_bg_hover;
                color: $slick_button_vertical_text_hover;
            }

            .slick-arrow:before {
                font-family: $default_font_icon;
                font-size: 20px;
                line-height: 1;
            }

            .slick-prev:before {
                content: "\e90f";
            }

            .slick-next {
                left: 42px;
            }

            .slick-next:before {
                content: "\e90c";
            }
        }

        .tt-slick-quantity {
            font-size: 14px;
            line-height: 1;
            color: $slick_quantity_text;
            font-family: $default_font2;

            .account-number {
                font-size: 20px;
                color: $slick_quantity_number;
            }
        }
    }


    .sc-item-details {
        .tt-add-info {
            margin-bottom: 10px;

            ul {
                list-style: none;
                margin: 0;
                padding: 0;
                display: flex;
                flex-direction: column;
                font-size: 16px;

                li {
                    margin-bottom: 10px;
                }
            }
        }
    }

    .tt-rating {
        margin-top: 10px;
        justify-content: flex-start;
        margin-bottom: 10px;
    }

    .tt-title {
        font-size: 30px;
        line-height: 40px;
        font-weight: 500;
        margin-top: 19px;
        color: $product_single_title_text;
    }

    .tt-price {
        font-size: 30px;
        line-height: 40px;
        font-family: $default_font2;
        font-weight: 500;
        color: $product_single_title_price;

        span {
            display: inline-block;
        }

        .sale-price {
            color: $product_single_title_price_sale;
            margin-right: 7px;
        }

        .old-price,
        .old-price .money {
            color: $product_single_title_price_old;
            text-decoration: line-through;
        }
    }

    .tt-wrapper {
        .sc-button {
            padding-left: 20px;
            padding-right: 20px;
        }
    }

    .tt-add-info {
        display: flex;
    }

    .tt-title-options {
        font-size: 14px;
        line-height: 26px;
        // color: $product_single_title_options_text;
        font-family: $default_font2;
        font-weight: 500;
        letter-spacing: 0.03em;

        .current_option_name {
            color: $default_color;
            margin-left: 5px;
        }
    }

    .tt-swatches-container {
        .tt-wrapper {
            margin-top: 10px;
            margin-bottom: 15px;
        }


        ul.tt-options-swatch {
            display: inline-flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-content: center;
            align-items: center;
            list-style: none;
            padding: 0;
            margin: 20px -1px 0 -5px;

            li {
                margin: 0 5px;

                a {
                    display: flex;
                    align-items: center;
                    text-align: center;
                    justify-content: center;
                    position: relative;
                    transition: all $value_speed linear;
                    font-size: 14px;
                    line-height: 1.1;
                }

                a:not(.options-color):not(.options-color-img) {
                    padding-left: 5px;
                    padding-right: 5px;
                }

                img {
                    width: 100%;
                    height: auto;
                    max-width: inherit;
                    border-radius: 50%;
                }
            }

            // options-size
            li {
                a:not(.options-color):not(.options-color-img) {
                    background: #eeeeee;
                    color: $tt_options_swatch_text;
                    width: 40px;
                    height: 40px;
                    padding: 0px 5px;
                    border-radius: 6px;
                }
            }

            li:hover,
            li.active {
                a:not(.options-color):not(.options-color-img) {
                    background: $tt_options_swatch_bg_hover;
                    color: $tt_options_swatch_text_hover;
                }
            }

            // options-color
            li {
                .options-color {
                    font-size: 0;
                    line-height: 0;
                    border-radius: 50%;
                    backface-visibility: hidden;
                }

                .tt-border {
                    border: 2px solid $tt_options_swatch_border;
                }

                .options-color:before {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    outline: none;
                    border-radius: 50%;
                    transition: all 0.12s linear;
                    backface-visibility: hidden;
                }

                .options-color:after {
                    content: "";
                    display: block;
                    position: absolute;
                    top: -1px;
                    left: -1px;
                    width: calc(100% + 2px);
                    height: calc(100% + 2px);
                    outline: none;
                    backface-visibility: hidden;
                    border-radius: 50%;
                    transition: all 0.12s linear;
                }

                .options-color:before {
                    border: 0 solid $tt_options_swatch_hover_border01;
                }

                .options-color:after {
                    border: 0 solid $tt_options_swatch_hover_border02;
                }

                .options-color:hover:before {
                    border-width: 6px;
                }

                .options-color:hover:after {
                    border-width: 3px;
                }

                .tt-border.options-color:hover {
                    border-color: transparent;
                }
            }

            li.active {
                .options-color:before {
                    border-width: 6px;
                }

                .options-color:after {
                    border-width: 3px;
                }
            }

            li {
                // options-color-img
                line-height: 0;
                font-size: 0;

                .options-color-img {
                    width: 40px;
                    height: 40px;
                    padding: 0 !important;
                    display: inline-block;
                    background-position: center top !important;
                    border-radius: 6px !important;
                    background-size: cover !important;

                    &:after {
                        content: "";
                        display: block;
                        position: absolute;
                        top: -1px;
                        left: -1px;
                        width: calc(100% + 2px);
                        height: calc(100% + 2px);
                        outline: none;
                        backface-visibility: hidden;
                        border-radius: 6px;
                        border: 0px solid $default_color;
                        transition: border-width 0.1s linear;
                    }

                    &:hover {
                        &:after {
                            border-width: 2px;
                        }
                    }
                }

                &.active {
                    .options-color-img {
                        &:after {
                            border-width: 2px;
                        }
                    }
                }
            }

            &.on-filter {
                margin-top: -10px;

                li {
                    margin: 10px 5px 0;
                }
            }
        }

    }



    .tt-row-custom-01 {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-content: flex-start;
        align-items: flex-start;

        .col-item:not(:last-child) {
            margin-right: 20px;
        }

        .col-item:nth-child(2) {
            flex: 1 1 auto;
        }

        .btn {
            width: 100%;
        }

        .btn.btn-lg {
            font-size: 14px;

            i {
                font-size: 20px;
                position: relative;
                top: -3px;
            }
        }
    }

    .tt-input-counter {
        user-select: none;
    }

    /* input-counter */
    .tt-input-counter.style-01 {
        position: relative;
        max-width: 132px;
        min-width: 132px;

        input {
            background: $product_single_input_counter_bg;
            display: block;
            width: 100%;
            height: 50px;
            font-size: 14px;
            line-height: 1em;
            text-align: center;
            border: none;
            outline: none;
            font-family: $default_font2;
            font-weight: 500;
            color: $product_single_input_counter_text;
            border-radius: $border_radius;
        }

        span {
            display: inline-block;
            font-size: 27px;
            line-height: 1em;
            cursor: pointer;
            position: absolute;
            width: 49px;
            height: 100%;
            text-align: center;
            top: 0;
            color: $product_single_input_counter_btn;
        }

        span:hover {
            color: $product_single_input_counter_btn_hover;
        }

        span.minus-btn {
            left: 0px;
        }

        span.plus-btn {
            right: 0px;
        }

        .minus-btn:before,
        .plus-btn:before {
            display: block;
            font-family: $default_font_icon;
            font-size: 12px;
            line-height: 50px;
        }

        .minus-btn:before {
            content: "\e9b2";
        }

        .plus-btn:before {
            content: "\e9b1";
        }
    }

    .accordion-item {
        margin-top: 30px;
    }

}