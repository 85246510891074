/* Reset Browsers
-------------------------------------------------------------- */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    font-family: inherit;
    font-size: 100%;
    font-style: inherit;
    font-weight: inherit;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block
}

html {
    font-size: 62.5%;
    overflow-y: scroll;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
}

*,
*:before,
*:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

body {
    background: var(--primary-color);
    line-height: 1;
    padding-right: 0 !important;
    overflow: hidden;
    height: 100%;
    width: 100%;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
nav,
section {
    display: block
}

ol,
ul {
    list-style: none
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

caption,
th,
td {
    font-weight: normal;
    text-align: left;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

blockquote,
q {
    quotes: none
}

a img {
    border: 0
}

img {
    max-width: 100%;
    height: auto;
}

select {
    max-width: 100%
}

/* General
-------------------------------------------------------------- */

body,
button,
input,
select,
textarea {
    font-family: 'Urbanist', sans-serif;
    color: var(--primary-color2);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

img {
    height: auto;
    max-width: 100%;
    vertical-align: middle;
    -ms-interpolation-mode: bicubic
}

p {
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: var(--primary-color4);
}


strong,
b,
cite {
    font-weight: bold;
}

dfn,
cite,
em,
i,
blockquote {
    font-style: italic;
}

abbr,
acronym {
    border-bottom: 1px dotted #e0e0e0;
    cursor: help;
}

mark,
ins {
    text-decoration: none;
}

sup,
sub {
    font-size: 75%;
    height: 0;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

small {
    font-size: 75%;
}

big {
    font-size: 125%;
}

address {
    font-style: italic;
    margin: 0 0 20px;
}

code,
kbd,
tt,
var,
samp,
pre {
    margin: 20px 0;
    padding: 4px 12px;
    background: #f5f5f5;
    border: 1px solid #e0e0e0;
    overflow-x: auto;
    -webkit-hyphens: none;
    -moz-hyphens: none;
    hyphens: none;
    border-radius: 0;
    height: auto;
}


/* Elements
-------------------------------------------------------------- */

html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

*,
*:before,
*:after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit;
}

hr {
    margin-bottom: 20px;
    border: dashed 1px #ccc;
}


/* List */

ul,
ol {
    padding: 0;
}

ul {
    list-style: disc;
}

ol {
    list-style: decimal;
}

li>ul,
li>ol {
    margin-bottom: 0;
}

li {
    list-style: none;
}

ul li,
ol li {
    padding: 0.1em 0;
}

dl,
dd {
    margin: 0 0 20px;
}

dt {
    font-weight: bold;
}

del,
.disable {
    text-decoration: line-through;
    filter: alpha(opacity=50);
    opacity: 0.5;
}


/* Table */

table,
th,
td {
    border: 1px solid #343444;
}

table {
    border-collapse: separate;
    border-spacing: 0;
    border-width: 1px 0 0 1px;
    margin: 0 0 30px;
    table-layout: fixed;
    width: 100%;
}

caption,
th,
td {
    font-weight: normal;
    text-align: left;
}

th {
    border-width: 0 1px 1px 0;
    font-weight: bold;
}

td {
    border-width: 0 1px 1px 0;
}

th,
td {
    padding: 8px 12px;
}

/* Media */

embed,
object,
video {
    margin-bottom: 20px;
    max-width: 100%;
    vertical-align: middle;
}

p>embed,
p>iframe,
p>object,
p>video {
    margin-bottom: 0;
}

/* Forms
-------------------------------------------------------------- */
/* Fixes */

button,
input {
    line-height: normal;
}

button,
input,
select,
textarea {
    font-size: 100%;
    line-height: inherit;
    margin: 0;
    vertical-align: baseline;
}

input,
textarea,
select {
    font-size: 14px;
    max-width: 100%;
    background-image: -webkit-linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));
}

textarea {
    overflow: auto;
    vertical-align: top;
}

input[type="checkbox"] {
    display: inline;
}

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
    line-height: 1;
    cursor: pointer;
    -webkit-appearance: button;
    border: 0;
}

input[type="checkbox"],
input[type="radio"] {
    padding: 0;
    width: 18px;
    height: 18px;
    margin-right: 11px;
    cursor: pointer;
    vertical-align: sub;
}

input[type="search"] {
    -webkit-appearance: textfield;
}

input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}


/* Remove chrome yellow autofill */

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px #f7f7f7 inset
}


/* Reset search styling */

input[type="search"] {
    outline: 0
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    display: none
}


/* Contact Form 7 */

.wpcf7-form select,
.wpcf7-form textarea,
.wpcf7-form input {
    margin-bottom: 0;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -ms-appearance: none;
}

.select {
    position: relative;
    overflow: hidden;
}

.select::after {
    font-family: 'Font Awesome 5 Pro';
    content: '\f078';
    font-size: 14px;
    right: 10px;
    font-weight: 600;
    color: #1F1F2C;
    display: block;
    position: absolute;
    background: 0 0;
    top: 50%;
    pointer-events: none;
    -webkit-transition: .25s all ease;
    -o-transition: .25s all ease;
    transition: .25s all ease;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}

select option {
    font-size: 15px;
    line-height: 24px;
    color: #1F1F2C;
}

textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"] {
    border: 1px solid rgba(138, 138, 160, 0.3);
    outline: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    font-size: 18px;
    line-height: 28px;
    border-radius: 4px;
    padding: 13px 15px;
    width: 100%;
    background: transparent;
    color: #8A8AA0;
}

/* Input focus */
textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus {
    border: 1px solid #8A8AA0;
    outline: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

textarea::placeholder,
input[type="text"]::placeholder,
input[type="password"]::placeholder,
input[type="datetime"]::placeholder,
input[type="datetime-local"]::placeholder,
input[type="date"]::placeholder,
input[type="month"]::placeholder,
input[type="time"]::placeholder,
input[type="week"]::placeholder,
input[type="number"]::placeholder,
input[type="email"]::placeholder,
input[type="url"]::placeholder,
input[type="search"]::placeholder,
input[type="tel"]::placeholder,
input[type="color"]::placeholder {
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    color: #8A8AA0;
}

/* Button */
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
    font-weight: 700;
    font-size: 15px;
    line-height: 22px;
    background-color: var(--primary-color3);
    color: #fff;
    border-radius: 30px;
    padding: 15px 39px;
    border: 1px solid var(--primary-color3);
    display: inline-block;
    -webkit-appearance: none;
    -webkit-transition: all ease 0.3s;
    -moz-transition: all ease 0.3s;
    transition: all ease 0.3s;
}

/* Button hover + focus */
button:hover,
input[type="button"]:hover,
input[type="reset"]:hover,
input[type="submit"]:hover,
button:focus,
input[type="button"]:focus,
input[type="reset"]:focus,
input[type="submit"]:focus {
    outline: 0;
    border: 1px solid var(--primary-color3);
    color: var(--primary-color3);
    /* background: transparent; */
}


/* Placeholder color */
::-webkit-input-placeholder {
    color: #8A8AA0;
}

:-moz-placeholder {
    color: #8A8AA0;
}

::-moz-placeholder {
    color: #8A8AA0;
    opacity: 1;
}

.error {
    font-size: 16px;
    color: var(--primary-color2);
    margin-bottom: 10px;
    -webkit-transition: all ease 0.3s;
    -moz-transition: all ease 0.3s;
    transition: all ease 0.3s;
}


/* Since FF19 lowers the opacity of the placeholder by default */

:-ms-input-placeholder {
    color: #8A8AA0;
}

/* Typography
-------------------------------------------------------------- */

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 700;
    text-rendering: optimizeLegibility;
}

h1 {
    font-size: 48px;
}

h2 {
    font-size: 36px;
}

h3 {
    font-size: 24px;
}

h4 {
    font-size: 20px;
}

h5 {
    font-size: 18px;
}

h6 {
    font-size: 15px;
}

.fs-16 {
    font-size: 16px;
}

/* h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
    color: inherit
} */

/* Extra classes
-------------------------------------------------------------- */
.msg-success {
    background: linear-gradient(to right, #E250E5, #4B50E6, #E250E5);
    background-clip: text;
    -webkit-background-clip: text;
    font-weight: 700;
    background-size: 200% 200%;
    animation: rainbow 2s ease-in-out infinite;
    color: rgb(0 0 0 / 0);
    transition: color .2s ease-in-out;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
}

label.error {
    color: red;
}

.live-auctions .swiper-button-next:after,
.live-auctions .swiper-button-prev:after {
    font-family: 'Font Awesome 5 Pro';
    font-size: 20px;
}

.live-auctions .swiper-button-next:after {
    content: "\f061";
}

.live-auctions .swiper-button-prev:after {
    content: "\f060";
}

.flex {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

.wrap-inner {
    display: block;
    width: 100%;
}

.center,
.text-center {
    text-align: center;
}

.fs-30 {
    font-size: 30px !important;
}

.fs-32 {
    font-size: 32px !important;
}

.pd-0 {
    padding: 0 !important;
}

.mg-t-29 {
    margin-top: 29px;
}

.mg-bt-50 {
    margin-bottom: 50px;
}

.mg-bt-60 {
    margin-bottom: 60px;
}

.mg-bt-62 {
    margin-bottom: 62px;
}

.mg-bt-10 {
    margin-bottom: 10px;
}

.mg-r-12 {
    margin-right: 12px;
}

.mg-bottom-0 {
    margin-bottom: 0;
}

.mg-t16 {
    margin-top: 16px;
}

.mg-bt-32 {
    margin-bottom: 32px !important;
}

.mg-bt-12 {
    margin-bottom: 12px;
}

.mg-bt-2 {
    margin-bottom: 2px;
}

.mg-bt-21 {
    margin-bottom: 21px;
}

.mg-l-39 {
    margin-left: 39px;
}

.mg-bt-31 {
    margin-bottom: 31px;
}

.mg-bt-43 {
    margin-bottom: 43px;
}

.mg-bt-23 {
    margin-bottom: 23px;
}

.mg-bt-16 {
    margin-bottom: 16px !important;
}

.mg-bt-20 {
    margin-bottom: 20px;
}

.mg-bt-30 {
    margin-bottom: 30px;
}

.mg-bt-13 {
    margin-bottom: 13px;
}

.mg-t-24 {
    margin-top: 24px;
}

.mg-t-20 {
    margin-top: 20px;
}

.mg-t-22 {
    margin-top: 22px;
}

.mg-t-15 {
    margin-top: 15px;
}

.mg-t-21 {
    margin-top: 21px;
}

.mg-t-37 {
    margin-top: 37px;
}

.mg-t-9 {
    margin-top: -9px;
}

.mg-t-4 {
    margin-top: -4px;
}

.mg-t-2 {
    margin-top: -2px;
}

.mg-t2 {
    margin-top: 2px;
}

.mg-t32 {
    margin-top: 32px;
}

.mg-bt-24 {
    margin-bottom: 24px;
}

.mg-bt-22 {
    margin-bottom: 22px;
}

.mg-bt-40 {
    margin-bottom: 40px;
}

.mg-bt-41 {
    margin-bottom: 41px;
}

.mg-bt-44 {
    margin-bottom: 44px;
}

.pad-r-50 {
    padding-right: 50px;
}

.pad-t-23 {
    padding-top: 23px;
}

.pad-t-24 {
    padding-top: 24px;
}

.pad-t-17 {
    padding-top: 17px;
}

.pad-t-20 {
    padding-top: 20px;
}

.pad-t-4 {
    padding-top: 4px;
}

.pad-b-60 {
    padding-bottom: 60px !important;
}

.pad-b-54 {
    padding-bottom: 54px !important;
}

.pad-b-74 {
    padding-bottom: 74px !important;
}

.mg-t-40 {
    margin-top: 40px;
}

.mg-t-36 {
    margin-top: 36px;
}

.mg-bt-18 {
    margin-bottom: 18px;
}

.pad-l-7 {
    padding-left: 7px;
}

.pad-0-15 {
    padding: 0 15px;
}

.mg-r-3 {
    margin-right: 3px;
}

.mg-r-1 {
    margin-right: 1px;
}

.color-47A432 {
    background-color: #47A432 !important;
}

.color-9835FB {
    background-color: #9835FB !important;
}

.color-DF4949 {
    background-color: #DF4949 !important;
}

.no-box-shadown {
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.pad-b-60 {
    padding-bottom: 60px !important;
}

.pad-b-20 {
    padding-bottom: 20px !important;
}

.pad-400 {
    padding: 0 400px;
}

.pad-420 {
    padding: 0 420px;
}

.mg-t-10 {
    margin-top: 10px;
}

.mg-l-8 {
    margin-left: 10px;
}

.mg-r-8 {
    margin-right: 10px;
}

.mg-t-11 {
    margin-top: 11px !important;
}

.mg-t-12 {
    margin-top: 12px !important;
}

.mg-t-6 {
    margin-top: 6px !important;
}

.pd-t-20 {
    padding-top: 20px;
}

.pd-15 {
    padding: 0 15px;
}

.bg-style {
    background-color: var(--primary-color5) !important;
}

.bg-style2 {
    background-color: #fff !important;
}

.is_dark .bg-style2 {
    background-color: #1F1F2C !important;
}

.bg-style3 {
    background-color: #F8F8F8;
}

.is_dark .bg-style3 {
    background-color: #1F1F2C !important;
}

.home-3 .bg-home-3 {
    background-color: var(--primary-color5) !important;
}

.home-1 .bg-home,
.home-2 .bg-home {
    background-color: var(--primary-color5) !important;
}

.mg-t-42 {
    margin-top: 42px;
}

.pb-10 {
    padding-bottom: 10px !important;
}

.pb-11 {
    padding-bottom: 11px !important;
}

.pb-12 {
    padding-bottom: 12px !important;
}

.pb-24 {
    padding-bottom: 24px !important;
}

.pb-16 {
    padding-bottom: 16px !important;
}

.pb-23 {
    padding-bottom: 23px !important;
}

.pb-39 {
    padding-bottom: 39px !important;
}

.pb-40 {
    padding-bottom: 40px !important;
}

.pd-40 {
    padding: 40px;
}

.pb-15 {
    padding-bottom: 15px !important;
}

.pb-17 {
    padding-bottom: 17px !important;
}

.pb-18 {
    padding-bottom: 18px !important;
}

.pb-20 {
    padding-bottom: 20px !important;
}

.pb-22 {
    padding-bottom: 22px !important;
}

.pb-24 {
    padding-bottom: 24px !important;
}

.mt-21 {
    margin-top: 21px;
}

.mt-29 {
    margin-top: 29px;
}

.mb-11 {
    margin-bottom: 11px;
}

.mb-35 {
    margin-bottom: 35px;
}

.mb-15 {
    margin-bottom: 15px;
}

.mt-6 {
    margin-top: 6px;
}

.mt-9 {
    margin-top: 9px;
}

.mt-21 {
    margin-top: 21px;
}

.pt-24 {
    padding-top: 24px;
}

.mb-25 {
    margin-bottom: 25px;
}

.mb-24 {
    margin-bottom: 24px;
}

.mg-bt-3 {
    margin-bottom: 3px;
}

.pl-17 {
    padding-left: 17px;
}

.pl-34 {
    padding-left: 34px;
}

.pl-51 {
    padding-left: 51px;
}

.pl-68 {
    padding-left: 68px;
}

.pl-20 {
    padding-left: 20px;
}

.mg-style2 {
    margin-left: -3px;
    margin-right: -3px;
}

#wrapper {
    position: relative;
    overflow: hidden;
    max-width: 100%;
    height: 100%;
}

.color-popup {
    color: var(--primary-color2);
}

/* link style
-------------------------------------------------------------- */
a {
    text-decoration: none;
    color: var(--primary-color2);
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    letter-spacing: 0.1px;
}

a:hover,
a:focus {
    color: var(--primary-color3);
    text-decoration: none;
    outline: 0;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.hover-ecfect,
.exp {
    background: linear-gradient(to right, #E250E5, #4B50E6, #E250E5);
    background-clip: text;
    -webkit-background-clip: text;
    font-weight: 700;

    background-size: 200% 200%;
    animation: rainbow 2s ease-in-out infinite;
    color: var(--primary-color2);
    transition: color .2s ease-in-out;
}

.hover-ecfect:hover,
.exp:hover {
    color: rgba(0, 0, 0, 0) !important;

}

@keyframes rainbow {
    0% {
        background-position: left
    }

    50% {
        background-position: right
    }

    100% {
        background-position: left
    }
}

.link-style-1 a {
    text-decoration: none;
    color: var(--primary-color4);
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.link-style-1 a:hover,
.link-style-1 a:focus {
    color: var(--primary-color3);
    text-decoration: none;
    outline: 0;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

/* Root
-------------------------------------------------------------- */

:root {
    /* color */
    --primary-color: #fff;
    --primary-color2: #1F1F2C;
    --primary-color3: #5142FC;
    --primary-color4: #7A798A;
    --primary-color5: #F8F8F8;
    --primary-color6: #14141F;
    --primary-color7: #F8F8F8;
    --bg-section: #fff;
    --bg-section2: #f8f8f8;
    --primary-color8: #7A798A;
    --primary-color9: #7A798A;
}

.is_dark {
    --primary-color: #343444;
    --primary-color2: #fff;
    --primary-color4: rgba(255, 255, 255, 0.9);
    --primary-color5: #030303;
    --primary-color6: #ffffff;
    --primary-color7: #343444;
    --bg-section: #14141F;
    --bg-section2: #14141F;
    --primary-color8: #fff;
    --primary-color9: #8A8AA0;
}

/* Header
-------------------------------------------------------------- */
.dark .logo_header {
    width: 135px !important;
    height: 56px !important;
}

header {
    padding-right: 0 !important;
}

.header_1 {
    position: absolute;
    z-index: 100;
    height: 80px;
    width: 100%;
    border-bottom: 1px solid rgba(235, 235, 235, 0.2);
}

.header_1.header_2.style2 {
    height: 80px;
    border: none;
    display: flex;
    align-items: center;
}

.admin_active {
    /* display: none; */
    margin-right: 20px;
}

.connect-wal .admin_active {
    display: block;
}

.main-nav {
    margin-left: 20px;
    top: 0;
    z-index: 0;
}


.header_1-inner {
    position: relative;
    height: 80px;
}

.header_1 .header_avatar {
    border-radius: 2000px;
    border: solid 1px #e2eef1;
    background: #f9fbfc;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    z-index: 9;
}

.header_1 .header_avatar .avatar {
    height: 40px;
    width: 40px;
    border-radius: 2000px;
    -o-object-fit: cover;
    object-fit: cover;
}

.header_1 .header_avatar .price {
    padding: 0 10px 0 20px;
    font-size: 16px;
    color: #14141F;
}

.header_1 .header_avatar .avatar_popup {
    position: absolute;
    z-index: 20;
    right: 0;
    padding: 20px;
    top: 60px;
    overflow: hidden;
    border-radius: 10px;
    background: #ffffff;
    -webkit-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
    -webkit-box-shadow: 0px 17px 13px 0px #192c4b05;
    box-shadow: 0px 17px 13px 0px #192c4b05;
    border: solid 1px #e2eef1;
    visibility: hidden;
    opacity: 0;
    -webkit-transform: translateY(-1rem);
    transform: translateY(-1rem);
    font-size: 16px;
}

.header_1 .header_avatar:hover .avatar_popup.visible {
    visibility: visible;
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
}


.header_1 .header_avatar .avatar_popup .links {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;

    &>a,
    &>button {
        margin-bottom: 15px;
    }
}

.header_1 .header_avatar .avatar_popup .links a {
    color: #183b56;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.header_1 .header_avatar .avatar_popup .links i {
    margin-right: 10px;
    font-size: 20px;
    width: 20px;
}

.header_1 .header_avatar .avatar_popup .links a:hover {
    color: #566ffe;
}

.header_1 .header__notifications .js-notifications-icon {
    cursor: pointer;
}

.is_dark .header_1 .header_avatar .avatar_popup .info p {
    color: #183b56;
}

.space-y-20> :not([hidden])~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(20px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(20px * var(--cb-space-y-reverse));
}

.mt-20 {
    margin-top: 20px;
}

.mt-10 {
    margin-top: 10px;
}

.mt-15 {
    margin-top: -15px;
}

.mt-19 {
    margin-top: 19px !important;
}

.mb-40 {
    margin-bottom: 40px !important;
}

.ml-10 {
    margin-left: 10px;
}

.avatar_popup .copy-text a,
.avatar_popup .copy-text span {
    color: #14141F;
}

/* Site Logo
-------------------------------------------------------------- */
#site-logo {
    height: 80px;
    display: flex;
    align-items: center;
}

.flat-search-btn {
    display: flex;
    padding: 16px 0;
    position: absolute;
    /* right: 15px; */
    right: 50px;
    top: 0;
    justify-content: end;
    align-items: center;
}

.main-logo img {
    width: 133px;
    height: 56px;
}

.main-logo img,
.main-logo h2 {
    float: left;
}

.logo-dark {
    display: none;
}

.logo-light {
    display: block;
}

.is_dark .logo-dark {
    display: block;
}

.is_dark .logo-light {
    display: none;
}

/* Menu
-------------------------------------------------------------- */
/* Main Nav */
.main-nav ul {
    margin: 0px;
}

.main-nav ul.menu {
    display: flex;
}

.main-nav ul li {
    position: relative;
    list-style: none;
}

.main-nav>ul>li {
    /* padding: 26px 25px 26px 13px; */
    padding: 26px 10px 26px 10px;
}

.main-nav>ul>li>a {
    position: relative;
    font-size: 18px;
    line-height: 26px;
    font-weight: 700;
    color: #fff;
    // padding-right: 15.5px;
}

.main-nav>.menu>li.menu-item-has-children>a::after {
    content: '\f107';
    font-family: 'Font Awesome 5 Pro';
    font-weight: 300;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}

/* Sub Menu */

.main-nav .sub-menu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 250px;
    background-color: #ffff;
    border-radius: 6px;
    box-shadow: -2px 6px 23px #b4b4b44f;
    z-index: 9999;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    -webkit-transform: translateY(15px);
    -moz-transform: translateY(15px);
    -ms-transform: translateY(15px);
    -o-transform: translateY(15px);
    transform: translateY(15px);
}

.main-nav .sub-menu::before {
    content: '';
    position: absolute;
    top: -5px;
    left: 25px;
    height: 15px;
    width: 15px;
    transform: rotate(45deg);
    background-color: #fff;
}

.main-nav .right-sub-menu {
    left: auto;
    right: 0;
}

.main-nav .sub-menu li a {
    display: block;
    font-size: 18px;
    color: #14141F;
    padding: 20px;
    font-weight: 700;
}

.main-nav .sub-menu li:not(:last-child) a {
    border-bottom: 1px solid #e1e1e18e;
}

.main-nav li:hover .sub-menu {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
}

.main-nav .sub-menu li.current-item a,
.main-nav .sub-menu li a:hover {
    color: var(--primary-color3);
}

.main-nav>ul>li>a:hover {
    color: #fff;
}

.main-nav>ul>li .sub-menu li {
    position: relative;
}

.main-nav>ul>li .sub-menu li a {
    transition: all 0.15s linear;
    -webkit-transition: all 0.15s linear;
    -moz-transition: all 0.15s linear;
    -o-transition: all 0.15s linear;
}

.main-nav>ul>li .sub-menu li a:hover,
.main-nav>ul>li .sub-menu li.current-item a {
    padding-left: 35px;
}

.main-nav>ul>li .sub-menu li a:hover::after,
.main-nav>ul>li .sub-menu li.current-item a:after {
    width: 12px;
}

.main-nav>ul>li .sub-menu li a::after {
    content: "";
    position: absolute;
    width: 0;
    height: 2px;
    background: var(--primary-color3);
    left: 15px;
    top: 28px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

@media only screen and (max-width: 1600px) {
    // .main-nav {
    //     left: 18%;
    // }

    .main-nav>ul>li {
        padding: 26px 12px 26px 12px;
    }
}

/* Mobile Menu */
@media only screen and (max-width: 1120px) {
    .main-nav {
        margin: 0 auto;
        width: 40%;
        height: 100vh;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 999999;
        background-color: #fff;
        box-shadow: 0px 3px 16px rgb(47 83 109 / 12%);
        transform: translateX(-100%);
        transition: transform 0.5s ease;

    }

    .is_dark .main-nav {
        background: #14141F;
    }

    .main-nav ul.menu {
        flex-direction: column;
    }

    .main-nav>ul>li:first-child {
        border: none;
    }

    .main-nav>ul>li {
        padding: 0;
        border-top: 1px solid rgba(138, 138, 160, 0.3);
    }

    .main-nav ul>li>a {
        line-height: 48px;
        padding: 0 15px;
        font-size: 16px;
        font-weight: 500;
        display: inline-block;
    }

    .main-nav.active {
        transform: translateX(0);
    }

    .main-nav.active ul {
        flex-direction: column;
    }

    .main-nav.active ul li {
        margin: 0;
        text-align: left;
        cursor: pointer;
        padding: 0;
        border-top: 1px solid rgba(138, 138, 160, 0.3);
        background-color: #fff;
    }

    .is_dark .main-nav.active ul li {
        background-color: #14141F;
    }

    .main-nav.active ul>li>a {
        color: #14141F;
        display: inline-block;
        font-size: 16px;
        line-height: 48px;
        padding: 0 15px;
        font-weight: 500;
    }

    .is_dark .main-nav.active ul>li>a {
        color: #fff;
    }

    .main-nav.active ul li:first-child {
        border-top: 0px;
    }

    .main-nav.active ul li>ul>li:first-child {
        border-top: 1px solid rgba(138, 138, 160, 0.3);
    }

    .main-nav.active ul>li.current-menu-item>a {
        color: var(--primary-color3);
    }

    .is_dark .main-nav.active ul>li.current-menu-item>a {
        background: linear-gradient(216.56deg, #E250E5 5.32%, #4B50E6 94.32%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .main-nav.active .current-item>a,
    .main-nav.active ul>li>a:hover {
        color: var(--primary-color3);
    }

    .is_dark .main-nav.active .current-item>a,
    .is_dark .main-nav.active ul>li>a:hover {
        background: linear-gradient(216.56deg, #E250E5 5.32%, #4B50E6 94.32%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .main-nav.active .sub-menu .menu-item a {
        margin-left: 15px;
    }


    .main-nav.active .menu-item-has-children .arrow {
        cursor: pointer;
        display: inline-block;
        font-size: 20px;
        font-family: "FontAwesome";
        line-height: 48px;
        position: absolute;
        right: 15px;
        text-align: center;
        top: 0;
        width: 48px;
    }

    .main-nav.active .menu-item-has-children .arrow:before {
        font-family: "Font Awesome 5 Pro";
        content: "\f107";
        color: var(--primary-color2);
    }

    .main-nav.active .menu-item-has-children.current-menu-item .arrow:before {
        color: var(--primary-color3);
    }

    .main-nav.active .menu-item-has-children.current-menu-item .arrow:before {
        background: linear-gradient(216.56deg, #E250E5 5.32%, #4B50E6 94.32%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }


    .main-nav.active .menu-item-has-children .arrow.active {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
    }

    .main-nav.active .menu-item-has-children .arrow.active:before {
        content: "\f107";
    }

    .main-nav.active ul ul li {
        background-color: var(--primary-color);
        border-top: 1px solid rgba(138, 138, 160, 0.3);
    }

    .is_dark .main-nav.active ul ul li {
        background-color: #14141F;
    }

    .main-nav .sub-menu {
        width: 100%;
    }

    .is_dark .main-nav .sub-menu {
        background: #14141F;
    }

    .main-nav .sub-menu::before {
        display: none;
    }

    .main-nav.active .sub-menu {
        position: relative;
        display: none;
        opacity: 1;
        visibility: visible;
        box-shadow: none;
    }

    .main-nav.active .menu-item.active .sub-menu {
        display: block;
    }

    .main-nav.active .sub-menu::before {
        display: none;
    }

    .main-nav.active .sub-menu li:not(:last-child) a {
        border: none;
    }

    .header_1.header_2 #main-nav {
        left: 0;
    }
}

@media only screen and (max-width: 767px) {
    .main-nav {
        width: 65%;
    }
}


/* Search icon */
#site-header .header-search-icon {
    display: block;
    font-size: 14px;
    line-height: 100px;
}

.opacity_0 {
    opacity: 0;
}

/* Search Form */
.header-search {
    position: relative;
    margin-right: 20px;
    padding: 14px 0;
}

.header-search .show-search {
    font-size: 20px;
    color: #fff;
}

.top-search.active {
    visibility: visible;
    filter: alpha(opacity=100);
    opacity: 1;
}

.top-search {
    position: absolute;
    right: -16px;
    top: 130%;
    width: 250px;
    opacity: 0;
    visibility: hidden;
}

.search-submit {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 20px;
    background: none;
    color: #14141F;
    border-radius: 0;
    -webkit-transition: all ease .3s;
    -moz-transition: all ease .3s;
    transition: all ease .3s;
    border: none;
    border-radius: 30px;
    line-height: 20px;
    padding: 15px 15px;
    background: transparent;
}

.search-submit:hover {
    border: none;
    color: #fff;
}

.search-form {
    position: relative;
}

.search-form .search-field:focus,
.search-form .search-field {
    background: #F8F8F8;
    color: #14141F;
    width: 100%;
    padding-left: 20px;
    border: 1px solid #fff;
    border-radius: 30px;
    line-height: 23px;
    padding: 13px 50px 13px 15px;
}

.search-form .search-field::placeholder {
    color: #14141F;
    line-height: 23px;
    font-size: 16px;
}

.icon-fl-search-filled {
    color: #14141F;
}

/* Mobile Menu Button */
.mobile-button {
    display: none;
    width: 30px;
    height: 30px;
    background-color: transparent;
    cursor: pointer;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    -webkit-transform: translateY(0%);
    -ms-transform: translateY(0%);
    -o-transform: translateY(0%);
    transform: translateY(0%);
}

.mobile-button:before,
.mobile-button:after,
.mobile-button span {
    background-color: #ffffff;
    -webkit-transition: all ease 0.3s;
    -moz-transition: all ease 0.3s;
    transition: all ease 0.3s;
}

.mobile-button:before,
.mobile-button:after {
    content: '';
    position: absolute;
    top: 0;
    height: 3px;
    width: 100%;
    left: 0;
    top: 50%;
    -webkit-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
}

.mobile-button span {
    position: absolute;
    width: 100%;
    height: 3px;
    left: 0;
    top: 50%;
    overflow: hidden;
    text-indent: 200%;
}

.mobile-button:before {
    -webkit-transform: translate3d(0, -7px, 0);
    -moz-transform: translate3d(0, -7px, 0);
    transform: translate3d(0, -7px, 0);
}

.mobile-button:after {
    -webkit-transform: translate3d(0, 7px, 0);
    -moz-transform: translate3d(0, 7px, 0);
    transform: translate3d(0, 7px, 0);
}

.mobile-button.active span {
    opacity: 0;
}

.mobile-button.active:before {
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    -moz-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
}

.mobile-button.active:after {
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    -moz-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
}

.tf-button-submit:hover {
    opacity: .9;
    color: #fff;
}

.is_dark .tf-button-submit:hover {
    color: #fff;
}

/* Header Fixed */

.header_1.is-fixed {
    position: fixed;
    top: -161px;
    left: 0;
    width: 100%;
    box-shadow: 1px 1px 5px 0px rgb(0 0 0 / 30%);
    z-index: 9999;
    background: linear-gradient(227.3deg, #8A208C 0%, #181B81 100.84%);
    border: none;
    opacity: 1;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.is_dark .header_1.is-fixed {
    background: #14141F;
    box-shadow: 1px 1px 5px 0px rgb(0 0 0 / 50%);
}

.header_1.header_2.is-fixed .mode_switcher {
    display: inline-flex;
}

.header_1.is-fixed.is-small {
    top: 0;

}

#header_admin.is-fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    box-shadow: 1px 1px 5px 0px rgb(0 0 0 / 30%);
    z-index: 9999;
    background-color: rgb(81 66 252 / 93%);
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}


.header_1.is-fixed .search-form .search-field:focus,
.header_1.is-fixed .search-form .search-field {
    background-color: #ffffff;
    box-shadow: 0px 2px 6px 0px rgb(47 47 47 / 30%);
}

.header_1.is-fixed .search-form .search-field:focus,
.header_1.is-fixed .search-form .search-field,
.header_1.is-fixed .search-form .search-field::placeholder,
.header_1.is-fixed .search-submit {
    color: #14141F;
}

/* Pagination
-------------------------------------------------------------- */
.themes-flat-pagination {
    margin: 32px 30px;
}

.themes-flat-pagination ul {
    margin: 0px;
    position: relative;
}

.themes-flat-pagination ul li {
    display: inline-block;
    padding: 0px;
    margin-right: 7px;
}

.themes-flat-pagination ul li .page-numbers {
    display: inline-block;
    font-size: 14px;
    font-family: 'Rubik', sans-serif;
    font-weight: 400;
    color: #787878;
    text-align: center;
    width: 29px;
    height: 30px;
    border-radius: 3px;
    border: 1px solid var(--primary-color4);
    line-height: 30px;
}

.themes-flat-pagination ul li .page-numbers:hover,
.themes-flat-pagination ul li .page-numbers.current {
    background-color: var(--primary-color);
    color: #fff;
    border-color: var(--primary-color);
}

.themes-flat-pagination ul li .next,
.themes-flat-pagination ul li .prev {
    font-size: 14px;
    font-weight: 700;
    color: #878787;
}

.themes-flat-pagination ul li .next:hover,
.themes-flat-pagination ul li .prev:hover {
    color: var(--primary-color);
}

/* Footer
-------------------------------------------------------------- */
#footer {
    padding: 80px 0 80px;
    background: var(--bg-section);
}

#footer.style-2 {
    background-color: #ffffff;
}

.home-5 #footer,
.home-3 #footer {
    background: #fff !important;
}

.home-6 #footer {
    background: var(--bg-section) !important;
}

.is_dark .home-5 #footer,
.is_dark .home-3 #footer {
    background: #0D0D11 !important;
}

.is_dark #footer.style-2 {
    background-color: #14141F;
}

/* Scroll Top
-------------------------------------------------------------- */
#scroll-top {
    position: fixed;
    display: block;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    z-index: 1;
    right: 14px;
    bottom: 23px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    cursor: pointer;
    background-color: #5142FC;
}

.is_dark #scroll-top {
    background: #fff;
}

#scroll-top:after {
    content: "\f077";
    font-family: "Font Awesome 5 Pro";
    font-size: 18px;
    color: #ffffff;
    font-weight: 600;
}

.is_dark #scroll-top:after {
    color: #5142FC;
}

#scroll-top:hover {
    transform: translateY(-7%);
}

/* Widget
-------------------------------------------------------------- */
.widget p,
.widget .title-widget {
    color: var(--primary-color2);
}

.fl-dark .widget p,
.fl-dark .widget .title-widget {
    color: var(--primary-color);
}

#footer .widget p {
    font-size: 14px;
    line-height: 22px;
}

.side-bar .widget p {
    font-size: 15px;
}

#footer .title-widget {
    line-height: 26px;
}

#side-bar .title-widget {
    line-height: 30px;
    margin-bottom: 20px;
}

#side-bar .title-widget.style-2 {
    line-height: 26px;
    margin-bottom: 14px;
}

#side-bar .widget {
    margin-bottom: 40px;
}

#side-bar .widget.mgbt-24 {
    margin-bottom: 24px;
}

.sc-widget.style-1 {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: space-between;
}

#side-bar.style-2 {
    padding-left: 50px;
}

#side-bar .widget.mgbt-0 {
    margin-bottom: 0;
}

#side-bar.style-3 {
    padding-right: 10px;
}

.sub-title.small {
    font-size: 14px;
    line-height: 22px;
}

.sub-title.style-2 {
    margin-bottom: 40px;
}

/*widget-logo*/
.widget-logo .logo-footer a {
    color: var(--primary-color3);
}

.widget-logo .logo-footer a {
    font-size: 36px;
    font-weight: bold;
}

.widget-logo .logo-footer {
    margin-bottom: 13px;
}

.widget-logo .sub-widget-logo {
    padding-right: 35px;
    margin-bottom: 26px;
}

/* widget-social */
.widget-social ul {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

.widget-social.style-1 a {
    background-color: #EBEBEB;
    color: #7A798A;
}

.is_dark .widget-social.style-1 a {
    background-color: #343444;
    color: #ffffff;
}

.widget-social.style-1 a:hover {
    color: var(--primary-color);
    background-color: var(--primary-color3);
}

.is_dark .widget-social.style-1 a:hover {
    color: #ffffff;
    background-color: var(--primary-color3);
}

.widget-social.style-1 ul li {
    margin-right: 12px;
}

.widget-social.style-1 ul li:last-child {
    margin-right: 0;
}

.widget-social.style-1 ul li a {
    width: 40px;
    height: 40px;
    border-radius: 5px;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 21px;
}

.widget-social.style-1 ul li.style-2 a {
    font-size: 22px;
}

.widget-social.style-2 {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

.widget-social.style-2 .title-widget {
    line-height: 26px;
    margin-right: 17px;
}

.widget-social.style-2 ul {
    margin-right: 3px;
}

.widget-social.style-2 ul li {
    margin-left: 12px;
}

.widget-social.style-2 ul li:first-child {
    margin-left: 0px;
}

.widget-social.style-2 ul li a {
    font-size: 16px;
    line-height: 26px;
    color: #7A798A;
}

.widget-social.style-2 ul li a:hover {
    color: var(--primary-color3);
}

.is_dark .widget-social.style-2 ul li a:hover {
    color: #fff;
}

.widget-social.style-3 ul li a {
    background-color: #fff;
    color: #14141F;
}

.widget-social.style-3 ul li a:hover {
    color: #fff;
    background-color: #5142FC;
}

.widget-social.style-3 ul li {
    margin-right: 12px;
}

.widget-social.style-3 ul li:last-child {
    margin-right: 0;
}

.widget-social.style-3 ul li a {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 25px;
}

.widget-social.style-3 ul li.style-2 a {
    font-size: 22px;
}


/* widget-menu */
.widget-menu {
    padding-top: 18px;
}

.widget-menu.style-1 {
    padding-left: 30px;
}

.widget-menu.style-2 {
    padding-left: 38px;
}

.widget-menu.fl-st-3 {
    padding-left: 33px;
}

.widget-menu .title-widget {
    margin-bottom: 24px;
}

.widget-menu ul li {
    margin-bottom: 14px;
}

.widget-menu ul li:last-child {
    margin-bottom: 0px;
}

.widget-menu ul li a {
    font-size: 14px;
    line-height: 22px;
    position: relative;
}

.widget-menu ul li a::after {
    content: '';
    width: 0;
    height: 1px;
    position: absolute;
    background: var(--primary-color3);
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.widget-menu ul li a:hover::after {
    width: 100%;
    visibility: visible;
    opacity: 1;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

/* widget-subcribe */
.widget-subcribe {
    padding-top: 17px;
    padding-left: 20px;
}

.widget-subcribe .title-widget {
    margin-bottom: 25px;
}

.widget-subcribe .sub-widget-subcribe {
    padding-right: 5px;
    margin-bottom: 23px;
}

.widget-subcribe .form-subcribe form {
    position: relative;
}

.widget-subcribe .form-subcribe input {
    border-radius: 10px;
    background: #F8F8F8;
    width: 100%;
    padding: 13px 50px 12px 22px;
    font-size: 14px;
    border: 1px solid rgba(138, 138, 160, 0.3);
}

.is_dark .widget-subcribe .form-subcribe input {
    background: transparent;
}

.widget-subcribe .form-subcribe input:focus {
    border: 1px solid #5142FC;
}

.widget-subcribe .form-subcribe input {
    border: 1px solid rgba(138, 138, 160, 0.3);
}

.widget-subcribe .form-subcribe input::placeholder {
    font-size: 14px;
    white-space: nowrap;
    max-width: 70%;
    overflow: hidden;
}

.widget-subcribe .form-subcribe button {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    position: absolute;
    top: 0;
    right: 0;
    background-color: var(--primary-color3);
    font-size: 21px;
    padding: 15px 18px 14px 19px;
    color: var(--primary-color);
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.widget-subcribe .form-subcribe button .icon-fl-send {
    color: #ffffff;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.widget-subcribe .form-subcribe button:hover .icon-fl-send {
    color: #fff;
}

.widget-subcribe .form-subcribe button:hover {
    opacity: .9;
}

/* widget-recent-post */
.box-recent-post,
.widget-recent-post ul li.box-recent-post {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    width: 100%;
    align-items: center;
    padding: 8px 12px 8px 8px;
    border-radius: 6px;
    cursor: default;
    box-shadow: 0px 2px 6px 0px rgb(47 47 47 / 10%);
}

.is_dark .widget-recent-post ul li.box-recent-post {
    background: #343444;
}

.widget-recent-post ul li.box-recent-post {
    margin-bottom: 16px;
}

.widget-recent-post ul li:last-child {
    margin-bottom: 0px;
}

.box-recent-post .box-feature img {
    width: 47px;
    height: 44px;
    border-radius: 6px;
    object-fit: cover;
}

.box-recent-post .box-content {
    padding-left: 10px;
    width: 86%;
}

.box-recent-post .box-content span {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: space-between;
}

.box-recent-post .box-content .title-recent-post {
    font-size: 14px;
    font-weight: bold;
    line-height: 22px;
}

.box-recent-post .box-content .sub-recent-post {
    font-size: 13px;
    line-height: 18px;
    margin-top: 4px;
    color: var(--primary-color4);
}

.box-recent-post .box-content .day-recent-post {
    font-size: 13px;
    line-height: 18px;
    margin-top: 4px;
    color: var(--primary-color9);
}

.box-recent-post .box-content .day-recent-post:hover {
    color: var(--primary-color3);
}

/* widget-tag */
.widget-tag.style-1 {
    margin-right: -5px;
}

.widget-tag ul {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-wrap: wrap;
}

.widget-tag.style-1 ul li {
    margin-right: 12px;
    margin-bottom: 13px;
}

.widget-tag a.box-widget-tag {
    line-height: 38px;
    padding: 0 28px;
    font-size: 15px;
    font-weight: 600;
    border-radius: 40px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
}

.widget-tag a.box-widget-tag {
    box-shadow: 0px 2px 6px 0px rgb(47 83 109 / 10%);
    background-color: var(--primary-color);
}

.widget-tag a.box-widget-tag:hover {
    background-color: var(--primary-color3);
    color: #fff;
}


.fl-dark .widget-tag a.box-widget-tag {
    background-color: #343444;
}

.fl-dark .widget-tag a.box-widget-tag:hover {
    background-color: var(--primary-color3);
    color: var(--primary-color);
}

.fl-dark .widget-tag a.box-widget-tag {
    background-color: #343444;
}

.fl-dark .widget-tag a.box-widget-tag:hover {
    background-color: var(--primary-color3);
    color: var(--primary-color);
}

.widget-tag.style-2 {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

.widget-tag.style-2 .title-widget {
    line-height: 26px;
    margin-right: 15px;
}

.widget-tag.style-2 ul li {
    margin-right: 6px;
}

.widget-tag.style-2 ul li::after {
    content: ',';
}

.widget-tag.style-2 ul li:last-child::after {
    content: '';
}

.widget-tag.style-2 ul li a {
    font-size: 18px;
    line-height: 28px;
}

.widget-tag.style-2 ul li a {
    color: #7A798A;
}

.widget-tag.style-2 ul li a:hover {
    color: var(--primary-color3);
}

.is_dark .widget-tag.style-2 ul li a:hover {
    color: #fff;
}

/* widget-search */
.widget-search form {
    position: relative;
}

.widget-search input {
    border-radius: 10px;
    background: transparent;
    width: 100%;
    padding: 10px 70px 8px 19px;
    font-size: 13px;
    border: 1px solid rgba(138, 138, 160, 0.3);
}

.widget-search input.style-2 {
    padding: 14px 70px 12px 19px;
    font-size: 14px;
}

.widget-search input.style-2::placeholder {
    letter-spacing: 0px;
}

.widget-search input:focus {
    border: 1px solid rgba(52, 52, 68, 1);
    background: 343444;
}

.widget-search input::placeholder {
    font-size: 14px;
    letter-spacing: -0.5px;
}

.widget-search button {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    position: absolute;
    top: 0;
    right: 0;
    background-color: var(--primary-color3);
    font-size: 17px;
    padding: 12.5px 22px;
    border: none;
    color: #fff;
}

.widget-search button.style-2 {
    padding: 16.5px 22px;
}

.widget-search button i {
    color: #fff;
}

/* widget-filter */
.widget-filter ul {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 8px;
}

.widget-filter.style-2 ul li {
    margin-right: 12px;
    margin-bottom: 13px;
}

.widget-filter a.box-widget-filter i {
    margin-right: 8px;
    color: var(--primary-color3);
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.is_dark .widget-filter a.box-widget-filter i {
    color: #fff;
}

.widget-filter a.box-widget-filter.active i,
.widget-filter a.box-widget-filter:hover i {
    color: #ffffff;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.widget-filter a.box-widget-filter {
    line-height: 38px;
    padding: 0 20px;
    font-size: 15px;
    font-weight: 600;
    border-radius: 40px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--primary-color2);
}

.widget-filter a.box-widget-filter {
    box-shadow: 0px 2px 6px 0px rgb(47 83 109 / 10%);
    background-color: var(--primary-color);
}

.widget-filter a.box-widget-filter.active,
.widget-filter a.box-widget-filter:hover {
    background-color: var(--primary-color3);
    color: #ffffff;
}


.widget-filter .btn-filter {
    color: var(--primary-color3);
    font-size: 16px;
    line-height: 26px;
    font-weight: 700;
    position: relative;
    cursor: pointer;
}

.widget-filter .btn-filter.style-2 {
    padding-top: 5px;
}

.widget-filter .btn-filter:hover {
    color: var(--primary-color2);
}

.widget-filter.style-1 .header-widget-filter {
    justify-content: space-between;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

.widget-filter.style-1 form label {
    line-height: 26px;
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 20px;
    position: relative;
    cursor: pointer;
    padding-left: 40px;
}

.widget-filter.style-1 form label.mgbt-none {
    margin-bottom: 0;
}

.widget-filter.style-1 input {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    margin-right: 0px;
}

.widget-filter .btn-checkbox {
    position: absolute;
    top: 0;
    left: 0;
    width: 28px;
    height: 28px;
    background-color: transparent;
    border-radius: 8px;
}

.widget-filter .btn-checkbox {
    border: 1px solid #7A798A;
}

.fl-dark .widget-filter .btn-checkbox {
    border: 1px solid #343444;
}

.widget-filter.style-1 form label input:checked~.btn-checkbox {
    background-color: var(--primary-color3);
    border: 1px solid var(--primary-color3);
}

.widget-filter .btn-checkbox:after {
    content: "";
    position: absolute;
    display: none;
}

.widget-filter.style-1 form label input:checked~.btn-checkbox:after {
    display: block;
}

.widget-filter .btn-checkbox:after {
    content: "\e921";
    font-family: 'nfts';
    left: 6px;
    top: 2px;
    color: #fff;
}

/* widget-category */
.widget-category.boder-bt {
    border-bottom: 1px solid #EBEBEB;
}

.is-dark .widget-category.boder-bt {
    border-bottom: 1px solid #343444;
}

.widget-category .title-wg-category {
    justify-content: space-between;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    cursor: pointer;
}

.widget-category .title-wg-category i {
    font-size: 7px;
    padding-top: 9px;
    font-weight: 600;
}

.widget-category .title-widget {
    line-height: 26px;
}

.widget-category form {
    margin-bottom: 23px;
}

.widget-category form label {
    line-height: 16px;
    font-size: 14px;
    margin-bottom: 12px;
    position: relative;
    cursor: pointer;
    padding-left: 24px;
    letter-spacing: -0.5px;
}

.widget-category form label.mgbt-none {
    margin-bottom: 0;
}

.widget-category .btn-checkbox {
    position: absolute;
    top: 0;
    left: 0;
    width: 16px;
    height: 16px;
    background-color: transparent;
    border-radius: 4px;
}

.widget-category .btn-checkbox {
    border: 1px solid #7A798A;
}

.widget-category form label input:checked~.btn-checkbox {
    background-color: var(--primary-color3);
    border: 1px solid var(--primary-color3);
}

.widget-category .btn-checkbox:after {
    content: "";
    position: absolute;
    display: none;
}

.widget-category form label input:checked~.btn-checkbox:after {
    display: block;
}

.widget-category .btn-checkbox:after {
    content: "\e921";
    font-family: 'nfts';
    font-size: 10px;
    left: 2px;
    top: 0px;
    color: #fff;
}

/* --------------widget-filter-----------*/

.widget-filter a i {
    margin-right: 8px;
}

.widget-filter a.box-widget-filter {
    line-height: 38px;
    padding: 0 20px;
    font-size: 15px;
    font-weight: 600;
    border-radius: 40px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
}

.widget-filter a.box-widget-filter {
    box-shadow: 0px 2px 6px 0px rgb(47 83 109 / 10%);
    background-color: var(--primary-color);
}

.fl-dark .widget-filter a.box-widget-filter {
    background-color: #343444;
}

.fl-dark .widget-filter a.box-widget-filter:hover {
    background: var(--primary-color3);
    color: var(--primary-color);
}

.widget-filter .btn-filter {
    color: var(--primary-color3);
    font-size: 16px;
    line-height: 26px;
    font-weight: 700;
    position: relative;
    cursor: pointer;
}

.widget-filter .btn-filter.style-2 {
    padding-top: 5px;
}

.widget-filter .btn-filter:hover {
    color: var(--primary-color2);
}

.fl-dark .widget-filter .btn-filter:hover {
    color: var(--primary-color);
}

.widget-filter.style-1 .header-widget-filter {
    justify-content: space-between;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

.widget-filter.style-1 form label {
    line-height: 26px;
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 20px;
    position: relative;
    cursor: pointer;
    padding-left: 40px;
}

.widget-filter.style-1 form label.mgbt-none {
    margin-bottom: 0;
}

.widget-filter.style-1 input {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    margin-right: 0px;
}

.widget-filter .btn-checkbox {
    position: absolute;
    top: 0;
    left: 0;
    width: 28px;
    height: 28px;
    background-color: transparent;
    border-radius: 8px;
}

.widget-filter .btn-checkbox {
    border: 1px solid #7A798A;
}

.widget-filter.style-1 form label input:checked~.btn-checkbox {
    background-color: var(--primary-color3);
    border: 1px solid var(--primary-color3);
}

.widget-filter .btn-checkbox:after {
    content: "";
    position: absolute;
    display: none;
}

.widget-filter.style-1 form label input:checked~.btn-checkbox:after {
    display: block;
}

.widget-filter .btn-checkbox:after {
    content: "\e921";
    font-family: 'nfts';
    left: 6px;
    top: 2px;
    color: #fff;
}

/* widget-category */
.widget-category.boder-bt {
    border-bottom: 1px solid #EBEBEB;
}

.is_dark .widget-category.boder-bt {
    border-color: #343444;
}

.widget-category .title-wg-category {
    justify-content: space-between;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    cursor: pointer;
}

.widget-category .title-wg-category i {
    font-size: 7px;
    padding-top: 9px;
    font-weight: 600;
}

.widget-category .title-widget {
    line-height: 26px;
}

.widget-category form {
    margin-bottom: 23px;
}

.widget-category form label {
    line-height: 16px;
    font-size: 14px;
    margin-bottom: 12px;
    position: relative;
    cursor: pointer;
    padding-left: 24px;
    letter-spacing: -0.7px;
}

.widget-category form label.mgbt-none {
    margin-bottom: 0;
}

.widget-category input {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    margin-right: 0px;
}

.widget-category.style3 input {
    position: relative;
    opacity: 1;
}

.widget-category .btn-checkbox {
    position: absolute;
    top: 0;
    left: 0;
    width: 16px;
    height: 16px;
    background-color: transparent;
    border-radius: 4px;
}

.widget-category .btn-checkbox {
    border: 1px solid #7A798A;
}

.widget-category form label input:checked~.btn-checkbox {
    background-color: var(--primary-color3);
    border: 1px solid var(--primary-color3);
}

.widget-category .btn-checkbox:after {
    content: "";
    position: absolute;
    display: none;
}

.widget-category form label input:checked~.btn-checkbox:after {
    display: block;
}

.widget-category .btn-checkbox:after {
    content: "\e921";
    font-family: 'nfts';
    font-size: 10px;
    left: 2px;
    top: 0px;
    color: #fff;
}


.header_avatar .avatar_popup .links button {
    display: flex;
    align-items: center;
    background-color: #fff;
    color: #183b56;
    padding: 0;
    font-weight: 400;
    border: none;
}


.wallet-wrapper .sc-box-icon-inner .sc-box-icon {
    cursor: pointer;
}

.countdown {
    position: relative;
}


.countdown .box {
    height: 90px;
    width: 90px;
    background-color: #140339;
    font-size: 30px;
    color: white;
    margin-right: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 4px;
}


.is_dark .countdown .box {
    background-color: #53486c;
}

.center .countdown {
    justify-content: center;
}

.right .countdown {
    justify-content: flex-end;
}

.countdown .box .text {
    font-weight: 700;
}

.countdown .box .lable {
    display: flex;
    justify-content: center;
    font-size: 16px;
    color: #c0b5b5;
}

.table-winners .fl-item2 .item {
    padding-top: 5px;
    padding-bottom: 5px;
}

.table-winners .fl-item2 .item .author .content {
    height: 44px;
    line-height: 44px;
}

.table-winners .fl-item2 .item .content-collection {
    height: 80px;
    line-height: 80px;
}

.table-winners .fl-item2 .item .column.td6 {
    padding-right: 11px;
    text-align: center;
}

// button
i.i-button,
button.p-button,
a.p-link {
    font-weight: 700;
    font-size: 15px;
    line-height: 22px;
    border-radius: 30px;
    padding: 12px 15px;
    background-color: $default_bg_color;
    border: 2px solid var(--primary-color3);
    color: var(--primary-color3);

    &:hover {
        background-color: var(--primary-color3);
        color: $default_bg_color;
    }

    &.full {
        width: 100%;
    }
}

.tf-section {
    .text-center {
        text-align: center;
    }
}