@import "variables.scss";
@import "./common.scss";
@import "ntfs.css";
@import "shortcodes.scss";
@import "responsive.css";
@import "animate.css";
@import "font-awesome.css";
@import "../font/wokiee/style.css";
/* @import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); */
@import "bootstrap.css";
@import './components/style.scss';