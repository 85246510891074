.header-wrapper {

    .tt-col-obj {
        .tt-dropdown-menu {
            visibility: hidden;
            box-shadow: 0 0 10px rgb(0 0 0 / 8%);
        }

        /* tt-cart-layout */
        .tt-cart {
            border: 2px solid #fff;
            color: #fff;
            border-radius: 50%;
            margin-left: 5px;
            margin-right: 10px;

            &.active {

                .tt-dropdown-toggle {
                    color: var(--primary-color3);
                    background-color: #fff;
                }

                .tt-dropdown-menu {
                    opacity: 1;
                    visibility: visible;
                    pointer-events: visible;
                    top: 100%;

                    @media (max-width: 1024px) {
                        display: block;
                        right: auto;
                        top: 0;
                        -webkit-transform: translate(0, 0);
                        -ms-transform: translate(0, 0);
                        transform: translate(0, 0);
                        -webkit-transform: translate3d(0, 0, 0);
                        transform: translate3d(0, 0, 0);
                    }
                }
            }

            &:hover {
                background-color: #fff;
                color: var(--primary-color3);
                transition: all 0.4s ease;

                .tt-dropdown-toggle {
                    color: var(--primary-color3) !important;
                }
            }

            &.inactive {
                .tt-dropdown-toggle {
                    color: #fff;
                }
            }

            .tt-dropdown-toggle {
                background-color: unset;
                border: unset;
                font-size: 24px;
                padding: 10px 10px;
                line-height: 26px;

                position: relative;

                .tt-badge-cart {
                    display: inline-block;
                    width: 18px;
                    height: 18px;
                    background: $header_cart_badge_bg;
                    color: $header_cart_badge_text;
                    border-radius: 50%;
                    position: absolute;
                    top: -15px;
                    right: -11px;
                    font-size: 12px;
                    line-height: 1.7;
                    pointer-events: none;
                }
            }

            .tt-cart-layout {
                .tt-cart-empty {
                    padding: 21px 20px 19px;
                    text-align: center;
                    display: block;

                    [class^="icon-"] {
                        font-size: 40px;
                        line-height: 1;
                        color: $header_cart_dropdown_empty_icon;
                    }

                    p {
                        margin-top: 7px;
                        letter-spacing: 0.01em;
                        color: $header_cart_dropdown_empty_text;
                    }
                }

                .tt-cart-content {
                    padding: 26px 20px 23px;

                    .tt-cart-list {
                        .tt-item {
                            display: flex;
                            flex-direction: row;
                            flex-wrap: nowrap;
                            justify-content: flex-start;
                            align-content: stretch;
                            align-items: flex-start;

                            .tt-item-img {
                                margin-top: 4px;

                                img {
                                    width: 67px;
                                    height: auto;
                                }
                            }

                            a:not([class]) {
                                display: flex;
                                width: 100%;
                                transition: color $value_speed linear;

                                .tt-title {
                                    color: $header_cart_title;
                                }
                            }

                            a:not([class]):hover {
                                .tt-title {
                                    color: $header_cart_title_hover;
                                }
                            }

                            .tt-item-img+.tt-item-descriptions {
                                margin-left: 20px;
                            }

                            .tt-item-descriptions {
                                flex: 2 1 auto;

                                .tt-title {
                                    font-family: $default_font;
                                    font-weight: 400;
                                    font-size: 14px;
                                    line-height: 22px;
                                    transition: color $value_speed linear;
                                }

                                .tt-add-info {
                                    list-style: none;
                                    margin: 4px 0 0 0;
                                    padding: 0 0 5px 0;

                                    li {
                                        color: $header_cart_add_info_text;
                                        font-size: 14px;
                                        line-height: 1;
                                    }
                                }

                                .tt-quantity {
                                    display: inline-flex;
                                    font-size: 14px;
                                    color: $header_cart_quantity;
                                }

                                .tt-price {
                                    display: inline-flex;
                                    font-size: 16px;
                                    color: $header_cart_price;
                                    font-weight: 500;
                                }

                                >*:nth-child(1) {
                                    margin-top: 0;
                                }
                            }
                        }

                        .tt-item:not(:first-child) {
                            margin-top: 16px;
                        }

                        .tt-item-close {
                            .tt-btn-close {
                                padding: 4px;
                                margin-right: -6px;
                                position: relative;
                                top: -1px;
                                background: none;
                                border: none;

                                &:before {
                                    content: "\ea83";
                                    color: $header_cart_btn_close;
                                    font-size: 15px;
                                    line-height: 22px;
                                    font-family: $default_font_icon;
                                    transition: color $value_speed linear;
                                }

                                &:hover:before {
                                    color: $header_cart_btn_close_hover;
                                }
                            }
                        }
                    }

                    .tt-cart-total-row {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        justify-content: space-between;
                        align-content: stretch;
                        align-items: flex-start;
                        border-top: 1px solid $header_cart_separator;
                        margin-top: 20px;
                        padding-top: 14px;

                        .tt-cart-total-title {
                            font-weight: 500;
                            color: $header_cart_total_title;
                            font-size: 14px;
                            font-family: $default_font2;
                        }

                        .tt-cart-total-price {
                            flex: 2 1 auto;
                            text-align: right;
                            font-size: 16px;
                            font-weight: 500;
                            color: $header_cart_total_price;
                        }
                    }

                    .tt-cart-btn {
                        display: flex;
                        flex-direction: column;
                        flex-wrap: nowrap;
                        justify-content: flex-start;
                        align-content: center;
                        align-items: center;
                        text-align: center;

                        .tt-item {
                            margin-top: 11px;
                            width: 100%;
                        }

                        .btn {
                            padding-left: 13px;
                            padding-right: 13px;
                            width: 100%;
                        }
                    }
                }
            }
        }

        .tt-dropdown-menu {
            opacity: 0;
            visibility: hidden;
            pointer-events: none;
            background: $header_dropdown_toggle_bg;
            position: absolute;
            right: 0;
            top: calc(100% + 15px);
            z-index: 22;
            transition: 0.4s;
        }



    }

    // .cart {
    //     position: relative;
    //     margin-right: 10px;
    //     border-radius: 5px;



    //     .tt-desktop-parent-cart {

    //         .tt-mobile-add {
    //             display: none;
    //         }

    //         position: absolute;
    //         right: 0px;
    //         min-width: 400px;
    //         background-color: #efefef;

    //         button {
    //             background-color: unset;
    //             padding: unset;
    //         }

    //     }



    //     .count {
    //         position: absolute;
    //         height: 18px;
    //         top: -11px;
    //         right: -12px;
    //         border-radius: 50%;
    //         position: absolute;
    //     }

    //     .bag {
    //         width: 40px;
    //         height: 40px;
    //         border-radius: 2000px;
    //         border: solid 1px #e2eef1;
    //         display: flex;
    //         align-items: center;
    //         justify-content: center;
    //         // background-color: #efefef;

    //         i {
    //             font-size: 24px;
    //             color: #efefef;
    //         }
    //     }


    //     .products-popup-wrapper {
    //         // position: absolute;
    //         // width: 45px;
    //         // height: 45px;

    //         .products-popup {
    //             position: absolute;
    //             visibility: hidden;
    //             height: 100px;
    //             width: 300px;
    //             background-color: $default_bg_color;
    //             right: 19px;
    //             top: 20px;
    //         }

    //     }


    //     &:hover {
    //         .products-popup {
    //             visibility: visible;
    //         }
    //     }

    // }

}