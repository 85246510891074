.today-deals-wrapper {
    .sc-card-product {

        .card-bottom {
            justify-content: center;

            .card-title {
                justify-content: center;
                margin-bottom: 0px;
            }
        }

        .sc-button.add-to-cart {
            padding: 5px 15px;
        }

        .meta-info {
            flex-direction: column;

            .price {
                display: flex;
                font-size: 16px;
                line-height: 20px;
                font-weight: 500;

                .new-price {
                    color: #f8353e;
                    margin-right: 7px;
                }

                .old-price {
                    color: #191919;
                    text-decoration: line-through;
                }

            }
        }

    }
}


.purchase-succeed-page-wrapper {
    .center {
        height: 600px;
        display: flex;
        justify-content: center;
        flex-flow: column nowrap;
    }
}

.account-page-wrapper {
    .account-wrap {
        margin: 60px auto;

        .account-content {
            display: flex;
            flex-flow: row wrap;

            .item {
                border: 2px solid #efefef;
                border-radius: 8px;
                padding: 14px 10px;
                width: 300px;
                height: 102px;
                display: flex;
                justify-content: flex-start;
                margin: 0 20px 20px 0;

                .desc {
                    .title {
                        // color: #111;
                        font-weight: 600;
                        font-size: 17px;
                    }

                    .comments {
                        margin-top: 5px;
                        font-size: 14px;
                        line-height: 22px;
                        font-weight: 300;
                        // color: #777;
                    }
                }

                &:hover {
                    // background: #eee;
                    cursor: pointer;
                }
            }
        }
    }
}

.membership-page-wrapper {

    .content {
        margin: 60px 10%;

        .status-wrap {
            border: 1px #F0F2F2 solid;
            background-color: #F0F2F2;
            padding: 14px 18px;
            border-radius: 8px;

            .status {
                display: flex;
                align-items: center;
                font-size: 24px;
                font-weight: 600;
            }
        }

        .accordion-body {
            .text1 {
                font-size: 16px;
                font-weight: 700;
            }

            .text2 {
                .highlight {
                    color: #c45500
                }
            }

            .text3,
            .text4 {
                color: #0066c0;
            }
        }
    }

}

.is_dark .membership-page-wrapper {
    .content .status-wrap {
        background-color: black;
    }

}



.order-page-wrapper {
    .content {
        margin: 50px 10%;

        table,
        th,
        td {
            border: unset;
        }
    }

    .tab-content {
        .search {
            margin: 10px auto;

            >select {
                width: 200px;
                padding: 5px;
                border-radius: 5px;
            }
        }

        .list {
            .list-item {
                border: 2px solid #efefef;
                border-radius: 8px;

                &:not(:last-child) {
                    margin-bottom: 20px;
                }

                .header {
                    display: flex;
                    background-color: #efefef;
                    padding: 20px;

                    // justify-content: space-between;
                    .date {
                        min-width: 20%;
                    }

                    .price {
                        min-width: 20%;
                    }

                    .address {
                        min-width: 20%;
                    }

                    .order {
                        flex-grow: 1;
                    }

                    .detail {
                        span:not(:last-child) {
                            margin-right: 10px;
                        }
                    }
                }

                .body {
                    padding: 20px;
                    display: flex;
                    justify-content: space-between;

                    .products {
                        .product {
                            display: flex;

                            img {
                                margin: 10px 10px 10px 0px;
                                width: 120px;
                                height: 120px;
                            }
                        }
                    }

                    >.buttons {
                        display: flex;
                        flex-flow: column nowrap;
                    }
                }

                .footer {
                    padding: 5px 20px 5px;
                    border-top: 2px solid #efefef;
                }
            }
        }

        button.p-button {
            padding: 5px;
            border-radius: 5px;
            margin: 5px 5px 5px 0;
        }

        .pagination {
            display: flex;
            justify-content: flex-end;
            margin: 20px auto;
        }
    }
}

.is_dark .order-page-wrapper {
    .content {
        .ant-tabs-nav-list {
            .ant-tabs-tab {
                color: white;

                .ant-tabs-tab-btn {
                    &:focus {
                        color: white;
                    }
                }
            }
        }

    }
}