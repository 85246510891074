.login-wrapper {
    .form-inner {
        .submit {
            font-weight: 700;
            font-size: 15px;
            line-height: 22px;
            border-radius: 30px;
            padding: 15px 39px;
            display: block;
            height: auto;

            &:hover {
                color: #fff;
            }
        }

        .input-item {
            margin-bottom: 0;
            border-radius: 8px;

            input {
                padding: 5px 10px;
                margin-bottom: 0;

                &:focus {
                    outline: none;
                    box-shadow: none !important;
                    border: none;
                }
            }
        }
    }
}

.is_dark {
    .login-wrapper {
        .form-inner {

            .input-item {
                margin-bottom: 0;
                border-radius: 8px;
                background: black;
                border-color: #fff;

                &:focus {
                    outline: none;
                    box-shadow: none !important;
                    // border: none;
                }
            }
        }
    }
}